<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__models">
          <v-col cols="12" md="2">
            <BlogFilter />
          </v-col>
          <v-col cols="12" md="10">
            <BlogItems />
          </v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
    <!-- --------------------------------------------------------------------
                                Modals :: BEGIN
    ---------------------------------------------------------------------->
    <template>
      <div class="text-center"></div>
    </template>
    <!-- --------------------------------------------------------------------
                                Modals :: END
    ---------------------------------------------------------------------->
  </div>
</template>
<script>
import BlogItems from "@/components/Blog/Items";
import BlogFilter from "@/components/Blog/Filter";
import {mapMutations} from "vuex";
export default {
  name: "blog",
  components: {
    BlogFilter,
    BlogItems,
  },
  methods: {
    ...mapMutations('blog', ['DESTROY_HISTORY']),
  },
  destroyed(){
    this.DESTROY_HISTORY()
  }
};
</script>
