<template>
  <v-form class="h-100" @submit.prevent="submit">
    <list :title="$_t('filters')" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12" class="pt-4 pb-0">
          <v-text-field
            :label="$_t('filterTitle')"
            color="SonicSilver"
            outlined
            dense
            v-model="filter.title.val"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <date-input
            :label="$_t('dateAfter')"
            color="SonicSilver"
            outlined
            dense
            v-model="filter.created_at.val1"
          ></date-input>
        </v-col>
        <v-col cols="12" class="py-0">
          <date-input
            :label="$_t('dateBefore')"
            color="SonicSilver"
            outlined
            dense
            v-model="filter.created_at.val2"
          ></date-input>
        </v-col>
        <v-col cols="12" class="py-0">
          <AutocompleteModel
            class="pt-0 mt-0"
            :label="$_t('modelTagged')"
            color="SonicSilver"
            v-model="filter.users_id.val"
          />
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="4" class="pr-0">
          <block-button
            height="30"
            class="w-100 cancel__button br__mode btn__size__14"
            :text="$_t('clear')"
            color="black"
            @click.native="clearFilter"
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
            height="30"
            class="w-100 site__button width-auto add__mode btn__size__18"
            :text="$_t('execute')"
            type="submit"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import AutocompleteModel from "@/components/Global/Input/AutocompleteModel.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
const defaultFilter = {
  created_at: {
    type: "between",
    val1: null,
    val2: null,
  },
  title: {
    type: "like",
    val: null,
  },
  users_id: {
    type: "in",
    val: null,
  },
};
export default {
  name: "blog_filter",
  components: {
    AutocompleteModel,
  },
  data() {
    return {
      filter: {},
    };
  },
  computed: {
    ...mapGetters("blog", ["getFiltersBlog"]),
  },
  methods: {
    ...mapActions("blog", ["loadBlogs", "setIsFiltered"]),
    ...mapMutations("blog", ["SET_FILTER_BLOG"]),
    submit() {
      this.setIsFiltered(true);
      this.SET_FILTER_BLOG(cloneDeep(this.filter));
      this.loadBlogs();
    },
    clearFilter() {
      this.setIsFiltered(false);
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_BLOG(cloneDeep(this.filter));
      this.loadBlogs();
    },
    loadPastFilter() {
      this.filter = cloneDeep({ ...defaultFilter, ...this.getFiltersBlog });
    },
  },
  created() {
    this.loadPastFilter();
  },
};
</script>
<style scoped>
/* filters */
.h-35 {
  height: 35px;
}
</style>
