<template>
  <list title="contents" class="wa__large">
    <hr class="mt-0" />
    <template #header-btn>
      <router-link
        :to="{ name: 'addBlog', params: { locale: $_getlocaleParam() } }"
        class="text-decoration-none"
      >
        <block-button
          text="add"
          icon="WMi-plus-linear"
          height="37"
          class="px-10 btn__size__18 site__button add__mode width-200 mt-n1"
          @click.native="$_openModal('add_blog')"
        ></block-button>
      </router-link>
    </template>
    <data-iterator
      :is-filtered="isFilteredBlog"
      :loading="getBlogLoading"
      :items="getBlogs"
      @pagination="changePagination"
      :pagination="getPaginationBlog"
      class="row blog__card pb-10"
    >
      <template #loading>
        <loading />
      </template>
      <template #no-items>
        <no-items />
      </template>
      <template #items="{ item }">
        <BlogItem :blog="item" />
      </template>
    </data-iterator>
    <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
        ---------------------------------------------------------------------->
    <template>
      <div class="text-center">
        <BlogTranslateModal v-if="isModal('modal_translate')" />
      </div>
    </template>
    <!-- --------------------------------------------------------------------
                                    Modals :: END
        ---------------------------------------------------------------------->
  </list>
</template>

<script>
import BlogItem from "./Item";
import BlogTranslateModal from "./Modal/Translate.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "blog_items",
  components: {
    BlogItem,
    BlogTranslateModal,
  },
  computed: {
    ...mapGetters("modal", ["isModal"]),
    ...mapGetters("blog", [
      "getBlogs",
      "getBlogLoading",
      "getPaginationBlog",
      "isFilteredBlog",
    ]),
  },
  methods: {
    ...mapMutations('blog', ['SET_PAGINATION']),
    ...mapActions("blog", ["loadBlogs"]),
    changePagination(pagination) {
      if (this.getPaginationBlog.page !== pagination.page) {
        this.SET_PAGINATION(pagination);
        this.loadBlogs();
      }
    },
  },
  created() {
    this.loadBlogs();
  },
};
</script>

<style scoped>
.blog__card {
  font-family: "Montserrat-light";
}
.btn-assign {
  font-size: 16px;
  padding: 15px 25px !important;
  margin-bottom: 25px;
}
</style>
