<template>
  <div>
    <v-autocomplete
      clearable
      :append-icon="null"
      hide-selected
      :label="label"
      outlined
      :color="color"
      dense
      multiple
      persistent-hint
      small-chips
      :items="models"
      v-model="model"
      item-text="name"
      item-value="id"
      @keyup="filterModels"
      :loading="isLoading"
      hide-no-data
      no-filter
    >
      <template #selection="{ item, parent }">
        <v-chip
          close
          @click:close="parent.selectItem(item)"
          class="mt-1 chip__tag"
          text-color="black"
          label
          small
        >
          {{ item.name }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import debouncedFunction from "@/utils/filter/autocompleteFilter.js";
import ModelRepository from "@/abstraction/repository/modelRepository";
const repository = new ModelRepository();
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
      validator: function (value) {
        return typeof +value === "number";
      },
    },
    label: {
      type: String,
      default: () => "",
    },
    color: {
      type: String,
      default: "SonicSilver",
    },
  },
  data() {
    return {
      models: [],
      model: this.value,
      isLoading: false,
    };
  },
  methods: {
    filterModels(event) {
      debouncedFunction(event, async () => {
        let filters = {
          name: {
            type: "like",
            val: event.target.value,
          },
        };
        let params = {
          filters,
          pagination: {
            page: 1,
          },
        };
        try {
          this.getModelsFromServer(params);
        } catch (err) {
          console.log(err, "there was an error loading models");
        } finally {
          this.isLoading = false;
        }
      });
    },
    async getModelsFromServer(params) {
      const response = await repository.index(params);
      this.models = this.models.filter((model) => {
        return this.model.findIndex((id) => id == model.id) !== -1;
      });
      this.models = [...this.models, ...response.data];
    },
    async loadModelsSelected() {
      if (this.model && this.model.length) {
        this.model.map(async (id) => {
          try {
            const response = await repository.show(id);
            this.models.push(response);
          } catch (err) {
            console.log(err, "there was an error loading models");
          }
        });
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.model = this.value;
      },
    },
    model: {
      deep: true,
      immediate: true,
      async handler() {
        if (
          this.model &&
          this.model.id &&
          !this.models.find((c) => c.id === this.model.id)
        ) {
          this.models.push(this.model);
        }

        this.$emit("input", this.model);
      },
    },
  },
  created() {
    this.loadModelsSelected();
  },
};
</script>
