<template>
  <v-col cols="12" sm="6" md="3" class="blog__card--item" @click="goToDetail(blog.id)">
    <div>
      <img :src="blog.thumbnail" class="w-100 rounded" alt="blog image" />
    </div>
    <div class="mt-3">
      <v-row>
        <v-col cols="12" sm="8">
          <div class="d-flex align-center mt-1 h-100">
            <div class="name__box">
              <div class="blog__name wa__f__m__eb">
                {{ blog.title }}
              </div>
              <div class="blog__schedule">
                <small class="mr-2">{{ blog.created_at_date }}</small>
                <span>{{ blog.created_at_time }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="d-flex align-center justify-end mr-n2">
            <block-button
              text="jp"
              :color="blog.verify_jpn ? 'cyan' : 'black'"
              :bgColor="blog.verify_jpn ? 'medium-turquoise' : ''"
              class="text-uppercase px-2 py-3 chip__language mr-2"
              x-small
              :loading="loadingVerifyJpn"
              @click.native.stop="verifyLang('jp')"
            ></block-button>
            <block-button
              text="en"
              :color="blog.verify_en ? 'cyan' : 'black'"
              :bgColor="blog.verify_en ? 'medium-turquoise' : ''"
              class="text-uppercase px-2 py-3 chip__language mr-2"
              x-small
              :loading="loadingVerifyEn"
              @click.native.stop="verifyLang('en')"
            ></block-button>
            <menu-item :items="menuItems" sm />
          </div>
        </v-col>
      </v-row>
      <div class="blog__description mb-2">
        {{ blog.description }}
      </div>
      <div class="blog__tags">
        <v-chip
          v-for="(tag, i) in blog.tags"
          :key="i"
          text-color="black"
          color="grey lighten-3"
          label
          class="mr-1"
          small
          >{{ tag }}</v-chip
        >
      </div>
    </div>
    <v-divider class="mt-1"></v-divider>
  </v-col>
</template>
<script>
import toast from "@/utils/toast";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loadingVerifyEn: null,
      loadingVerifyJpn: null,
    };
  },
  props: {
    blog: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    menuItems() {
      return [
        {
          text: "Translate",
          icon: "WMi-globe",
          click: (self) => {
            self.$_openModal("translate", { model: { id: this.blog.id } });
          },
        },
        {
          text: "edit",
          icon: "WMi-pencil",
          route: {
            routeName: "editBlog",
            params: {
              locale: this.$_getlocaleParam(),
              id: this.blog.id,
            },
          },
        },
        {
          text: "delete",
          icon: "WMi-trash",
          click: () => {
            this.deleteBlogMessage();
          },
        },
      ];
    },
  },
  methods: {
    async verifyLang(lang) {
      try {
        if (lang === "en") {
          this.loadingVerifyEn = true;
        } else if (lang === "jp") {
          this.loadingVerifyJpn = true;
        }
        const data = { ...this.blog, ...{ lang } };
        this.verifyBlog(data);
      } catch (error) {
        console.error(error);
        return error;
      } finally {
        this.loadingVerifyJpn = false;
        this.loadingVerifyEn = false;
      }
    },
    ...mapActions("blog", ["deleteBlog", "verifyBlog"]),
    goToDetail(id) {
      this.$router.push({
        name: "blogDetail",
        params: { id, locale: this.$_getlocaleParam() },
      });
    },
    deleteBlogMessage() {
      toast.question(this.$_trans("toast.del_blog_item_msg"), this.$_trans("toast.del_blog_item_title"), () => {
        this.deleteBlog(this.blog.id);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.blog__card--item {
  cursor: pointer !important;
}
.name__box {
  line-height: 16px;
  display: grid;
}
.blog__name {
  font-family: "Montserrat-regular";
  font-size: 14px;
  color: var(--color-black);
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.blog__schedule {
  font-size: 10px;
  color: #898989;
}
.chip__language {
  font-family: "Barlow-EL";
  font-size: 16px;
  margin-right: 1px !important;
}
.blog__description {
  font-size: 10px;
  color: #414141;
  text-align: justify;
}
.blog__tags {
  height: 28px;
  line-height: 26px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}
</style>
