<template>
  <basic-modal @open="setData" width="1250" transition="slide-x-transition">
    <template #header>
      <divider title="blog translate" sub-title="make it local" size="small"></divider>
    </template>
    <template #default>
      <div class="mt-4 px-15 pr-13">
        <v-row>
          <v-col cols="12" sm="8" class="pb-0">
            <v-text-field
              label="title"
              color="SonicSilver"
              class="mt-0 pt-0"
              dense
              outlined
              v-model="form.title_jpn"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="8" class="pt-0">
            <v-textarea
              color="SonicSilver"
              rows="3"
              label="summary"
              dense
              outlined
              v-model="form.summary_jpn"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" v-if="showEditor" class="pt-0">
            <div class="blog__text__lable">blog text</div>
            <editor :init="init" v-model="form.text_jpn"></editor>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              clearable
              :append-icon="null"
              hide-selected
              label="tags"
              outlined
              color="SonicSilver"
              dense
              multiple
              persistent-hint
              small-chips
              v-model="form.tags"
            >
              <template #selection="{ item }">
                <v-chip close class="mt-1 chip__tag" text-color="black" label small>
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-10 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__size__18 pL-0"
          text="cancel"
          icon="WMi-cancel-1"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign btn__size__16 site__button width-200 ml-5"
          text="translate"
          icon="WMi-plus"
          :loading="loadingSendData"
          @click.native="translateBlog"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
import { makeid } from "@/utils/math";
import { initTiny } from "@/plugins/tinymce/tinymce";
import BlogRepository from "@/abstraction/repository/blog/blogRepository";
import { mapActions } from "vuex";
const blogRepository = new BlogRepository();
const RANDOM_TOKEN = makeid(50);
var formDefault = {
  tinymce_batch_id: RANDOM_TOKEN,
};
export default {
  name: "modal_translate",
  components: {
    Editor,
  },
  data: () => ({
    init: initTiny(formDefault.tinymce_batch_id),
    showEditor: false,
    form: {},
    loadingSendData: false,
  }),
  methods: {
    ...mapActions("blog", ["updateBlog"]),
    setData({ model }) {
      if (model.id) {
        this.loadBlogById(model.id);
      }
    },
    async loadBlogById(id) {
      let response = await blogRepository.show(id);
      this.form = { ...this.form, ...response };
      this.form.users = this.form.users_ids;
    },
    async translateBlog() {
      try {
        this.loadingSendData = true;
        let response = await this.updateBlog(this.form);
        if (!(response instanceof Error)) {
          this.$_closeModal();
        }
      } catch (error) {
        console.error(error);
        return error;
      } finally {
        this.loadingSendData = false;
      }
    },
  },
  mounted() {
    this.showEditor = true;
  },
};
</script>
<style scoped>
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
.chip__tag {
  font-family: "montserrat-regular";
  font-size: 12px;
}
.blog__text__lable {
  font-family: "Montserrat-light" !important;
  font-size: 14px !important;
  color: #acacac !important;
  font-weight: 100;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}
</style>
